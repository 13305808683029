<template>
	<div>
		<jy-dialog
			type="table"
			:title="title"
			:visible.sync="dialogVisible"
			custom-class="dialog_table"
			:width="global.dialogTabelWidth"
		>
			<div>
				<jy-table max-height="595" :data="lists">
					<jy-table-column type="index" width="60" label="序号" align="center"> </jy-table-column>
					<jy-table-column prop="operateDate" label="营运日期" min-width="100"> </jy-table-column>
					<jy-table-column prop="setOutTime" label="发车时间" min-width="100"> </jy-table-column>
					<jy-table-column prop="bizTypeName" label="方向" min-width="100"> </jy-table-column>
					<jy-table-column prop="startStationName" label="起始场站" min-width="100"> </jy-table-column>
					<jy-table-column prop="startStopName" label="起始站点" min-width="100"> </jy-table-column>
					<jy-table-column prop="endStationName" label="终止场站" min-width="100"> </jy-table-column>
					<jy-table-column prop="endStopName" label="终止站点" min-width="100"> </jy-table-column>
				</jy-table>
				<jy-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="pageIndex"
					:page-size="pageSize"
					:total="total"
				></jy-pagination>
			</div>
			<div slot="footer" class="dialog-footer"></div>
		</jy-dialog>
	</div>
</template>
<script>
export default {
	data() {
		return {
			title: "行车计划明细",

			lists: [],
			dialogVisible: false,
			id: "",

			pageSize: 10,
			total: 0,
			pageIndex: 1
		};
	},
	watch: {
		dialogVisible(newview) {
			if (!newview) {
				this.pageIndex = 1;
			}
		}
	},
	methods: {
		// 打开对话框
		init(row) {
			this.dialogVisible = true;
			this.id = row.replyId;
			this.getList();
		},
		getList() {
			let url = "/stat/VplanReply/queryVplanDetailed";
			let option = {
				vplanReplyId: this.id,
				pageIndex: this.pageIndex,
				pageSize: this.pageSize
			};
			this.$http.post(url, option).then(({ detail }) => {
				this.lists = detail.list;
				this.total = detail.total;
			});
		},
		// 分页
		handleSizeChange(val) {
			this.pageSize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			this.pageIndex = val;
			this.getList();
		}
	}
};
</script>
<style lang="scss" scoped></style>
